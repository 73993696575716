<template>
  <div class="the-settings-header">
    <header class="the-header-whole">
      <img
        class="settings-gear"
        src="@/assets/images/settings_gear.png"
        width="27px"
        height="27px"
        alt="settings-gear"
      />
      <div class="settings-header-title">
        設定
      </div>
      <div class="right-block">
        <div class="contact-us" @click="pushToContactUs">
          <img
            class="img"
            src="@/assets/images/contact_us.png"
            width="21px"
            height="21px"
            alt="contact-us"
          />
          <div class="text">お問い合わせ</div>
        </div>
        <a :href="helpLink" target="_blank" rel="noopener noreferrer">
          <div class="help">
            <div class="mark">?</div>
            <div class="text">ヘルプ</div>
          </div>
        </a>
      </div>
    </header>
  </div>
</template>

<script>
import HelpLink from '@/components/mixins/HelpLink'

export default {
  name: 'TheSettingsHeader',

  mixins: [HelpLink],

  computed: {
    helpLink() {
      return this.mixinHelpLink()
    }
  },

  methods: {
    pushToContactUs() {
      this.$router.push({ name: 'contact-us' })
    }
  }
}
</script>

<style lang="scss" scoped>
.the-settings-header {
  display: flex;
  height: 64px;
  width: 100%;
  > .the-header-whole {
    width: 100%;
    height: 64px;
    box-sizing: border-box;
    border-bottom: solid 1px;
    background-color: #{$brown-gray};
    color: #{$white};
    > .settings-gear {
      position: relative;
      float: left;
      left: 33px;
      top: 19px;
    }
    > .settings-header-title {
      position: relative;
      float: left;
      left: 55px;
      top: 19px;
      font-size: 17px;
    }
    > .right-block {
      display: flex;
      height: 64px;
      width: 300px;
      margin: 0 0 0 auto;
      > .contact-us {
        display: flex;
        height: 64px;
        width: 150px;
        position: relative;
        > .img {
          position: absolute;
          top: 22px;
          height: 21px;
          width: 21px;
        }
        > .text {
          line-height: 64px;
          padding-left: 30px;
        }
        @include hover();
      }
      > a {
        > .help {
          display: flex;
          height: 64px;
          width: 100px;
          margin-left: 20px;
          > .mark {
            line-height: 64px;
            margin-right: 10px;
            font-size: 26px;
            color: #{$white};
          }
          > .text {
            line-height: 64px;
            color: #{$white};
          }
          @include hover();
        }
      }
    }
  }
}
</style>
