import dedent from 'dedent'

export const mailTextNotReply = dedent`
  ==============================
  本メールは送信専用です。
  本メールに返信しないようお願いいたします。
  ==============================`

export const mailTextNotReplyEn = dedent`
  ==============================
  Please do not reply to this email.
  ==============================`

export const mailTextReservationInformation = ({
  showReservationPurpose = true
} = {}) => {
  return showReservationPurpose
    ? dedent`
        飼主様名：xx xx
        患者様名：xxxx
        予約日時：20xx年xx月xx日xx時xx分～
        来院目的：xxxx`
    : dedent`
        飼主様名：xx xx
        患者様名：xxxx
        予約日時：20xx年xx月xx日xx時xx分～`
}

export const mailTextReservationInformationEn = ({
  showReservationPurpose = true
} = {}) => {
  return showReservationPurpose
    ? dedent`
        Owner's Name: xx xx
        Pet's Name: xxxx
        Reservation Date and Time: MM/DD/YYYY at hh:mm AM/PM onwards
        Purpose of the Visit: xxxx`
    : dedent`
        Owner's Name: xx xx
        Pet's Name: xxxx
        Reservation Date and Time: MM/DD/YYYY at hh:mm AM/PM onwards`
}
