<template>
  <div class="send-mail-template-input-form">
    <div class="form-item name">
      <text-input-form
        :requiredFlg="true"
        :maxlength="50"
        :placeholder="'50文字まで'"
        :headingStyle="{ width: '240px' }"
        :styles="{ width: '360px' }"
        v-model="sendMailTemplate.name"
        >定型文名</text-input-form
      >
    </div>
    <div class="form-item title">
      <text-input-form
        data-test="title"
        :requiredFlg="true"
        :maxlength="50"
        :placeholder="'50文字まで'"
        :headingStyle="{ width: '240px' }"
        :styles="{ width: '360px' }"
        v-model="sendMailTemplate.title"
        >件名</text-input-form
      >
    </div>
    <div class="form-item message textarea">
      <div class="label">
        本文
        <div class="required">必須</div>
      </div>
      <div class="input-field">
        <div
          class="sentence before-textarea"
          data-test="sentence before-textarea"
        >
          <span>{{ clinic.name }}より〇〇様へ</span>
        </div>
        <validation-provider
          class="validation-provider"
          :rules="{ requiredRule: true, notSurrogatePair: true }"
          v-slot="{ errors }"
        >
          <base-multiple-lines-text-box
            data-test="message"
            :styles="{ width: '360px' }"
            v-model="sendMailTemplate.message"
          />
          <div class="error">{{ errors[0] }}</div>
        </validation-provider>
        <div
          class="sentence after-textarea"
          data-test="sentence after-textarea"
        >
          <span>{{ mailTextNotReply }}</span>
        </div>
      </div>
    </div>
    <div v-if="enAvailable" class="form-item title-english">
      <text-input-form
        data-test="title-english"
        :requiredFlg="true"
        :maxlength="100"
        :placeholder="'100文字まで'"
        :headingStyle="{ width: '240px' }"
        :styles="{ width: '360px' }"
        v-model="sendMailTemplate.titleEnglish"
        >件名（英語）</text-input-form
      >
    </div>
    <div v-if="enAvailable" class="form-item message-english textarea">
      <div class="label">
        本文（英語）
        <div class="required">必須</div>
      </div>
      <div class="input-field">
        <div
          class="sentence before-textarea english"
          data-test="sentence before-textarea english"
        >
          <span>From {{ clinic.nameEnglish }} to 〇〇.</span>
        </div>
        <validation-provider
          class="validation-provider"
          :rules="{ requiredRule: true, notSurrogatePair: true }"
          v-slot="{ errors }"
        >
          <base-multiple-lines-text-box
            data-test="message-english"
            :styles="{ width: '360px' }"
            v-model="sendMailTemplate.messageEnglish"
          />
          <div class="error">{{ errors[0] }}</div>
        </validation-provider>
        <div
          class="sentence after-textarea english"
          data-test="sentence after-textarea english"
        >
          <span>{{ mailTextNotReplyEn }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mailTextNotReply, mailTextNotReplyEn } from '@/utils/mail_text'

export default {
  name: 'SendMailTemplateInputForm',

  components: {
    TextInputForm,
    BaseMultipleLinesTextBox,
    ValidationProvider
  },

  props: {
    sendMailTemplate: {
      name: { type: String },
      title: { type: String },
      message: { type: String },
      titleEnglish: { type: String },
      messageEnglish: { type: String }
    }
  },

  data() {
    const reservationSetting = this.$store.getters['reservationSetting/getData']
    return {
      clinic: this.$store.getters['clinic/getData'],
      enAvailable: reservationSetting.englishOptionFlg === 1,
      mailTextNotReply,
      mailTextNotReplyEn
    }
  }
}
</script>

<style lang="scss" scoped>
$form-width: 600px;

.send-mail-template-input-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  > .form-item.textarea {
    display: flex;
    align-items: flex-start;
    > .label {
      width: 240px;
      line-height: 33px;
      font-size: 15px;
      display: flex;
      align-items: center;
      > .required {
        margin-left: 10px;
        font-size: 13px;
        color: #{$tomato};
      }
    }
    > .input-field {
      flex: 1;
      > .validation-provider {
        display: block;
        padding: 20px 0;
      }
      > .sentence {
        font-size: 15px;
        white-space: pre-wrap;
        word-break: break-word;
        &.before-textarea {
          padding: 6px 0;
          line-height: 21px;
        }
      }
      @include validate-message();
    }
  }
}
</style>
