import store from '@/store/store'
import { getAxiosObject } from '@/utils/library'
import { toKebabCase } from '@/utils/convert_string'

export default {
  data() {
    return {
      mixinSortModeFlg: false,
      mixinSortModeMessage:
        'の並び順をドラッグ＆ドロップで変更した後、『並び順登録』ボタンを押して保存して下さい',
      mixinSortPopup: {
        showFlg: false,
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる']
      },
      mixinSortSucceeded: false
    }
  },

  methods: {
    async mixinUpdateOrder(dataName, data) {
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.put(`/${toKebabCase(dataName)}/order`, {
          orderIds: data.map(v => v.id)
        })
        if (res.status === 200) {
          store.dispatch(`${dataName}/updateAfter`, res.data[dataName])
          this.mixinSortSucceeded = true
          this.mixinSortPopup.type = 'success'
          this.mixinSortPopup.title = '完了'
          this.mixinSortPopup.message = '並び順を登録しました'
        }
      } catch (error) {
        this.mixinSortPopup.type = 'failure'
        this.mixinSortPopup.title = '失敗'
        this.mixinSortPopup.message = '並び順登録に失敗しました'
      }
      this.mixinSortPopup.showFlg = true
    },
    mixinCloseSortPopup() {
      this.mixinSortSucceeded = false
      this.mixinSortPopup.showFlg = false
      if (this.mixinSortPopup.type === 'success') {
        this.mixinSortModeFlg = false
      }
    }
  }
}
