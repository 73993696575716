<template>
  <div class="delivery-setting">
    <div class="wrap-content">
      <div class="fixed-display" v-if="checkPath">
        <div class="setting-title">メール配信設定</div>
        <div class="setting-tab-title-bar">
          <div
            v-for="titleObj in tabTitles"
            :key="titleObj.id"
            :class="`tab-` + titleObj.id"
          >
            <base-setting-tab-title
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
            />
          </div>
        </div>
        <div class="setting-tab-title-bar">
          <div
            v-for="titleObj in tabTitles2"
            :key="titleObj.id"
            :class="`tab-` + titleObj.id"
          >
            <base-setting-tab-title
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
            />
          </div>
        </div>
      </div>
      <div class="content-body">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'

export default {
  name: 'DeliverySetting',

  components: {
    BaseSettingTabTitle
  },

  data() {
    return {
      tabTitles: [
        {
          id: 1,
          title: '前日確認',
          link: 'previous-mail',
          names: ['previous-mail']
        },
        {
          id: 2,
          title: '予約申請',
          link: 'request-mail',
          names: ['request-mail']
        },
        {
          id: 3,
          title: '予約完了',
          link: 'reservation-mail',
          names: ['reservation-mail']
        },
        {
          id: 4,
          title: '予約キャンセル',
          link: 'cancel-mail',
          names: ['cancel-mail']
        },
        {
          id: 5,
          title: '予約変更',
          link: 'change-mail',
          names: ['change-mail']
        }
      ],
      tabTitles2: [
        {
          id: 6,
          title: 'メールフッター設定',
          link: 'mail-setting',
          names: ['mail-setting']
        },
        {
          id: 7,
          title: '定型文',
          link: 'send-mail-templates',
          names: ['send-mail-templates']
        }
      ]
    }
  },
  computed: {
    checkPath() {
      return this.$route.params.id ||
        this.$route.path === '/settings/delivery/send-mail-templates/new'
        ? false
        : true
    }
  }
}
</script>
<style lang="scss" scoped>
.delivery-setting {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  > .wrap-content {
    > .fixed-display {
      position: sticky;
      position: -webkit-sticky;
      top: 24px;
      transform: translateY(-24px);
      padding-top: 24px;
      margin-bottom: -24px;
      z-index: 100;
      background-color: #{$white};
      > .setting-title {
        font-size: 20px;
        font-weight: bold;
      }
      > .setting-tab-title-bar {
        display: flex;
        flex-direction: row;
        height: 26px;
        margin-top: 20px;
        padding-bottom: -4px;
        border-bottom: 1px solid;
        border-color: #{$light-grey};
        box-sizing: border-box;
      }
    }
    > .content-body {
      min-width: 614px;
      margin-top: 40px;
    }
  }
}
</style>
