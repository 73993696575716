<template>
  <div class="base-sort-button">
    <button
      class="sort-button"
      type="button"
      data-e2e="base-sort-button"
      @click="handleClick"
    >
      <slot name="link">
        <img
          src="@/assets/images/up_down_allow.png"
          width="18px"
          height="21px"
          alt="up-down-allow"
        />
        <div class="text">{{ text }}</div>
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseSortButton',
  props: {
    text: { type: String, default: '表示の並び替え' }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-sort-button {
  > .sort-button {
    box-sizing: border-box;
    width: 135px;
    height: 33px;
    border-radius: 6px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      height: 31px;
      margin-top: 2px;
    }
    > .text {
      font-size: 13px;
      font-weight: bold;
      color: #{$gray};
    }
  }
}
</style>
