<template>
  <div class="cancel-mail">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="input-area">
        <div class="row change-mail-auto">
          <div class="label">予約キャンセル時のメール自動送信</div>
          <base-radio-button
            v-for="option in radioButtonData"
            :key="option.id"
            v-model="editData.cancelMailAuto"
            :option="option"
            :styles="{ fontSize: '15px' }"
          />
        </div>
        <div class="row mail-text">
          <div class="label">予約キャンセル時の送信メール</div>
          <div class="field">
            <div class="content subject">
              <div class="title">件名</div>
              <div class="text">予約キャンセル完了のお知らせ（返信不可）</div>
            </div>
            <div class="content body">
              <div class="title">本文</div>
              <div class="text">
                <div class="upper-text">{{ mixinMailTextAboveInput }}</div>
                <div class="input-text">
                  <validation-provider
                    class="validation-provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      class="text-form"
                      :styles="textareaStyle"
                      :wrap="'off'"
                      v-model="editData.cancelMail"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="under-text">{{ mixinMailTextBelowInput }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="enAvailable" class="row mail-text">
          <div class="label">予約キャンセル時の送信メール（英語）</div>
          <div class="field">
            <div class="content subject">
              <div class="title">件名</div>
              <div class="text">
                <span>{{
                  'Your reservation has been cancelled (no reply)'
                }}</span>
              </div>
            </div>
            <div class="content body">
              <div class="title">本文</div>
              <div class="text">
                <div class="upper-text">{{ mixinMailTextAboveInputEn }}</div>
                <div class="input-text">
                  <validation-provider
                    class="validation-provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      class="text-form"
                      :styles="textareaStyle"
                      :wrap="'off'"
                      v-model="editData.cancelMailEnglish"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="under-text">{{ mixinMailTextBelowInputEn }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-area">
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="invalid || waitFlg"
          @click="updateData"
          >登録</base-button-medium-orange
        >
      </div>
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import MailTemplate from '@/components/mixins/MailTemplate'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import dedent from 'dedent'
import {
  mailTextReservationInformation,
  mailTextReservationInformationEn
} from '@/utils/mail_text'

export default {
  name: 'CancelMail',

  components: {
    BaseMultipleLinesTextBox,
    BaseButtonMediumOrange,
    BaseRadioButton,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [MailTemplate, CheckInputDifference],

  data() {
    return {
      editData: {},
      enAvailable: false,
      radioButtonData: [
        { id: 1, eachValue: 'auto', labelName: '有効' },
        { id: 2, eachValue: 'check', labelName: '毎回確認' },
        { id: 3, eachValue: 'off', labelName: '無効' }
      ],
      textareaStyle: {
        width: '500px',
        height: 'auto',
        minHeight: '150px',
        fontSize: '13px',
        resize: 'both'
      },
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる']
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      const reservationSetting = this.$store.getters[
        'reservationSetting/getData'
      ]

      // 入力内容の初期化
      this.editData = {
        id: reservationSetting.id,
        cancelMailAuto: reservationSetting.cancelMailAuto,
        cancelMail: reservationSetting.cancelMail,
        cancelMailEnglish: reservationSetting.cancelMailEnglish
      }
      this.mixinInputData = this.editData
      this.mixinSetInitialData()

      // 英語表記の設定を反映
      this.enAvailable = reservationSetting.englishOptionFlg === 1

      // メール固定文を設定
      this.mixinSetMailTexts()
      this.mixinMailTextAboveInput = dedent`
        ${this.mixinClinicName}をご利用いただきありがとうございます。
        以下の予約をキャンセルしました。

        ${mailTextReservationInformation()}`
      this.mixinMailTextAboveInputEn = dedent`
        Thank you for using ${this.mixinClinicNameEn}.
        The following reservation has been cancelled.

        ${mailTextReservationInformationEn()}`
    },
    async updateData() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'reservationSetting/updateMailText',
        this.editData
      )
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.setData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cancel-mail {
  @include delivery-component-style($label-min-width: 270px);
}
</style>
