<template>
  <div class="mail-setting">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="input-area">
        <div class="row mail-text">
          <div class="label">メールフッター設定</div>
          <div class="field">
            <div class="input-text">
              <validation-provider
                class="validation-provider"
                :rules="{ notSurrogatePair: true }"
                v-slot="{ errors }"
              >
                <base-multiple-lines-text-box
                  class="text-form"
                  :styles="formStyles"
                  :wrap="'off'"
                  v-model="editData.footerText"
                />
                <div class="error">{{ errors[0] }}</div>
              </validation-provider>
            </div>
          </div>
        </div>
        <div v-if="enAvailable" class="row mail-text">
          <div class="label">メールフッター設定（英語）</div>
          <div class="field">
            <div class="input-text">
              <validation-provider
                class="validation-provider"
                :rules="{ notSurrogatePair: true }"
                v-slot="{ errors }"
              >
                <base-multiple-lines-text-box
                  class="text-form"
                  :styles="formStyles"
                  :wrap="'off'"
                  v-model="editData.footerTextEnglish"
                />
                <div class="error">{{ errors[0] }}</div>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
      <div class="button-area">
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="invalid || waitFlg"
          @click="updateData"
          >登録</base-button-medium-orange
        >
      </div>
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'MailSetting',

  components: {
    BaseMultipleLinesTextBox,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      editData: {},
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      formStyles: {
        width: '500px',
        height: 'auto',
        minHeight: '150px',
        fontSize: '13px',
        resize: 'both'
      },
      enAvailable: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      reservationSetting: 'reservationSetting/getData'
    })
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.editData = _.cloneDeep(this.$store.getters['mailSetting/getData'])
      this.mixinInputData = this.editData
      this.mixinSetInitialData()
      this.enAvailable = this.reservationSetting.englishOptionFlg === 1
    },
    async updateData() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'mailSetting/update',
        this.editData
      )
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.setData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mail-setting {
  @include delivery-component-style();
}
.input-text {
  @include validate-message();
}
</style>
