<template>
  <div class="waiting-status-input-table">
    <div class="table">
      <div class="table-header">
        <div class="table-header-cell center reorder">
          <img
            src="@/assets/images/up_down_allow.png"
            width="18px"
            height="21px"
            alt="up-down-allow"
          />
        </div>
        <div class="table-header-cell name">
          <span>待合状況名</span>
        </div>
        <div class="table-header-cell color">
          <span>表示カラー</span>
        </div>
        <div class="table-header-cell center menu-displayable-status-flg">
          <span>リスト表示</span>
        </div>
        <div class="table-header-cell center time-settable-status-flg">
          <span>来院時間設定</span>
          <hint-balloon :note="notes.timeSettableStatusFlg" />
        </div>
        <div class="table-header-cell center initial-value-flg">
          <span>待合状況初期選択値</span>
        </div>
        <div class="table-header-cell next-waiting-status-id">
          <span>次の待合状況</span>
        </div>
        <div v-if="karteFlg" class="table-header-cell linked-content">
          <span>リスト遷移画面</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell reservation-selectable-status-flg"
        >
          <span>予約での選択</span>
          <hint-balloon :note="notes.reservationSelectableStatusFlg" />
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell reservation-changeable-status-flg"
        >
          <span>予約での変更</span>
          <hint-balloon :note="notes.reservationChangeableStatusFlg" />
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell medical-content-create-waiting-status-id"
        >
          <span>診療内容作成後の遷移先</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell medical-content-delete-waiting-status-id"
        >
          <span>診療内容削除後の遷移先</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell medical-payment-create-waiting-status-id"
        >
          <span>診療明細作成後の遷移先</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell medical-payment-delete-waiting-status-id"
        >
          <span>診療明細削除後の遷移先</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell in-hospital-medical-payment-create-waiting-status-id"
        >
          <span>入院中診療明細作成後の遷移先</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell in-hospital-medical-payment-delete-waiting-status-id"
        >
          <span>入院中診療明細削除後の遷移先</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell payment-create-waiting-status-id"
        >
          <span>会計後の遷移先</span>
        </div>
        <div
          v-if="karteFlg"
          class="table-header-cell payment-delete-waiting-status-id"
        >
          <span>再会計後の遷移先</span>
        </div>
        <div class="table-header-cell center delete" />
      </div>
      <draggable
        class="table-body"
        :class="{ dragging }"
        data-test="table-body"
        v-bind="dragOptions"
        v-model="draggableWaitingStatuses"
        @start="$emit('ondragstart')"
        @end="$emit('ondragend')"
      >
        <waiting-status-input-table-row
          v-for="(waitingStatus, index) in waitingStatuses"
          :key="waitingStatus.id"
          :data-test="`waiting-status-input-table-row-${waitingStatus.id}`"
          :index="index"
          :initialValueWaitingStatusIndex="initialValueWaitingStatusIndex"
          :karteFlg="karteFlg"
          :timeSettableWaitingStatusIndex="timeSettableWaitingStatusIndex"
          :waitingStatus="waitingStatus"
          :waitingStatuses="waitingStatuses"
          @input="$emit('input', $event)"
          @remove="$emit('remove', $event)"
        />
      </draggable>
    </div>
    <div class="add-button">
      <base-button-plus
        data-test="add-button"
        :text="'待合状況追加'"
        @click="$emit('add')"
      />
    </div>
  </div>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import Draggable from 'vuedraggable'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import WaitingStatusInputTableRow from '@/components/parts/molecules/WaitingStatusInputTableRow'
import dedent from 'dedent'

export default {
  name: 'WaitingStatusInputTable',

  components: {
    BaseButtonPlus,
    Draggable,
    HintBalloon,
    WaitingStatusInputTableRow
  },

  props: {
    dragging: { type: Boolean },
    karteFlg: { type: Number },
    waitingStatuses: { type: Array }
  },

  data() {
    return {
      dragOptions: {
        animation: 200,
        dragClass: 'drag',
        forceFallback: true,
        ghostClass: 'ghost',
        handle: '.handle'
      },
      notes: {
        reservationChangeableStatusFlg: dedent`
          予約の待合状況が、「可能」と設定された待合状
          況となっている場合、予約ダイアログボックスで
          待合状況の値を変更することができます。

          予約の待合状況が、「不可能」と設定された待合
          状況になっている場合、予約ダイアログボックス
          で待合状況の値を変更することはできません。`,
        reservationSelectableStatusFlg: dedent`
          「可能」と設定された待合状況は、予約ダイアログ
          ボックスの待合状況欄で選択できるようになります。

          「不可能」と設定された待合状況は、予約ダイアロ
          グボックスの待合状況欄で選択できなくなります。`,
        timeSettableStatusFlg: dedent`
          予約の待合状況の値が、この「来院時間設定」で
          選択した待合状況に切り替わった場合、そのとき
          の時刻を予約の来院時間として自動設定します。`
      }
    }
  },

  computed: {
    draggableWaitingStatuses: {
      get() {
        return this.waitingStatuses
      },
      set(sortedWaitingStatuses) {
        this.$emit('sort', sortedWaitingStatuses)
      }
    },
    initialValueWaitingStatusIndex() {
      return this.waitingStatuses.findIndex(v => v.initialValueFlg)
    },
    timeSettableWaitingStatusIndex() {
      return this.waitingStatuses.findIndex(v => v.timeSettableStatusFlg)
    }
  }
}
</script>

<style lang="scss" scoped>
$question-mark-width: 25px;
$cell-widths: (
  reorder: 40px,
  name: 195px,
  color: 220px,
  menu-displayable-status-flg: 80px,
  time-settable-status-flg: 100px + $question-mark-width,
  initial-value-flg: 145px,
  next-waiting-status-id: 185px,
  linked-content: 125px,
  reservation-selectable-status-flg: 125px,
  reservation-changeable-status-flg: 125px,
  medical-content-create-waiting-status-id: 185px,
  medical-content-delete-waiting-status-id: 185px,
  medical-payment-create-waiting-status-id: 185px,
  medical-payment-delete-waiting-status-id: 185px,
  in-hospital-medical-payment-create-waiting-status-id: 225px,
  in-hospital-medical-payment-delete-waiting-status-id: 225px,
  payment-create-waiting-status-id: 185px,
  payment-delete-waiting-status-id: 185px,
  delete: 40px
);

.waiting-status-input-table {
  > .table {
    margin-top: 40px;
    width: 100%;
    > .table-header {
      box-sizing: border-box;
      width: fit-content;
      height: 50px;
      display: flex;
      align-items: center;
      gap: 0 40px;
      padding: 0 20px;
      > .table-header-cell {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        &.center {
          justify-content: center;
        }
        @each $key, $width in $cell-widths {
          &.#{$key} {
            width: $width;
          }
        }
      }
    }
    > .table-body {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &.dragging {
        cursor: move;
      }
      > .drag {
        background-color: #{$very-light-grey};
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
      }
      > .ghost {
        visibility: hidden;
      }
    }
  }
  > .add-button {
    margin-top: 30px;
  }
}
</style>
