<template>
  <div class="care-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">診察内容編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <care-input-form class="input-form" :care="care" />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="openShowPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import CareInputForm from '@/components/parts/organisms/CareInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'CareEdit',

  components: {
    CareInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      care: {},
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.care = { ...this.$store.getters['cares/getDataById'](this.id) }
    this.mixinInputData = this.care
    this.mixinSetInitialData()
  },

  methods: {
    openShowPage() {
      this.$router.push({ name: 'care-show', params: this.id })
    },
    async updateData() {
      this.waitFlg = true
      const result = await this.$store.dispatch('cares/update', this.care)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'no data' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の診察内容は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.openShowPage()
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'cares' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.care-edit {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
