// メール配信設定で使用する mixin

import dedent from 'dedent'
import { mailTextNotReply, mailTextNotReplyEn } from '@/utils/mail_text'

export default {
  data() {
    return {
      mixinClinicName: '',
      mixinClinicNameEn: '',
      mixinMailTextAboveInput: '',
      mixinMailTextAboveInputEn: '',
      mixinMailTextBelowInput: '',
      mixinMailTextBelowInputEn: ''
    }
  },

  methods: {
    mixinSetMailTexts() {
      // textarea の上部にあるテキストに使用する値を設定
      const clinic = this.$store.getters['clinic/getData']
      this.mixinClinicName = clinic.name
      this.mixinClinicNameEn = clinic.nameEnglish || clinic.name

      // textarea の下部にあるテキストに使用する値を設定
      const plusNetUrl = process.env.VUE_APP_DEV_PLUS_CLINIC_URL
      const encryptedClinicId = this.$store.getters[
        'clinic/getEncryptedClinicId'
      ]
      const plusNetUrlLoginTop = `${plusNetUrl}/login-top?clinic=${encryptedClinicId}`
      const { footerText, footerTextEnglish } = this.$store.getters[
        'mailSetting/getData'
      ]
      this.mixinMailTextBelowInput = dedent`
        予約の確認はこちらの画面からお願いいたします。
        ${plusNetUrlLoginTop}

        ${footerText}

        ${mailTextNotReply}`
      this.mixinMailTextBelowInputEn = dedent`
        You can check your reservation status by clicking on the following link.
        ${plusNetUrlLoginTop}

        ${footerTextEnglish}

        ${mailTextNotReplyEn}`
    }
  }
}
