<template>
  <div class="previous-mail">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="input-area">
        <div class="row send-flg">
          <radio-button-form v-bind="flgData" v-model="previousMail.sendFlg" />
        </div>
        <div class="row send-time">
          <div class="label">送信時間</div>
          <div class="form">
            <base-select-box
              :selectData="timeList"
              v-model.number="idMadeByTime"
            />
          </div>
        </div>
        <div class="row mail-text">
          <div class="label">前日確認メールの編集</div>
          <div class="field">
            <div class="content subject">
              <div class="title">件名</div>
              <div class="text">予約確認のお知らせ（返信不可）</div>
            </div>
            <div class="content body">
              <div class="title">本文</div>
              <div class="text">
                <div class="upper-text">{{ mixinMailTextAboveInput }}</div>
                <div class="input-text">
                  <validation-provider
                    class="validation-provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      class="text-form"
                      :styles="textareaStyle"
                      :wrap="'off'"
                      v-model="previousMail.message"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="under-text">{{ mixinMailTextBelowInput }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mail-text" v-if="enAvailable">
          <div class="label">前日確認メールの編集 (英語)</div>
          <div class="field">
            <div class="content subject">
              <div class="title">件名</div>
              <div class="text">Reservation confirmation notice (no reply)</div>
            </div>
            <div class="content body">
              <div class="title">本文</div>
              <div class="text">
                <div class="upper-text">{{ mixinMailTextAboveInputEn }}</div>
                <div class="input-text">
                  <validation-provider
                    class="validation-provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      class="text-form"
                      :styles="textareaStyle"
                      :wrap="'off'"
                      v-model="previousMail.messageEnglish"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="under-text">{{ mixinMailTextBelowInputEn }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-area">
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="invalid || waitFlg"
          @click="updateData"
          >登録</base-button-medium-orange
        >
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import MailTemplate from '@/components/mixins/MailTemplate'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import dedent from 'dedent'
import {
  mailTextReservationInformation,
  mailTextReservationInformationEn
} from '@/utils/mail_text'

export default {
  name: 'PreviousMail',

  components: {
    RadioButtonForm,
    BaseSelectBox,
    BaseMultipleLinesTextBox,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [MailTemplate, CheckInputDifference],

  data() {
    return {
      previousMail: {},
      enAvailable: false,
      flgData: {
        title: '予約前日確認',
        radioButtonData: [
          { id: 1, eachValue: 1, labelName: '有効' },
          { id: 2, eachValue: 0, labelName: '無効' }
        ]
      },
      timeList: [...Array(24).keys()].map(v => {
        return { id: v + 1, name: v + ':00' }
      }),
      textareaStyle: {
        width: '500px',
        height: 'auto',
        minHeight: '150px',
        fontSize: '13px',
        resize: 'both'
      },
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる']
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    idMadeByTime: {
      get() {
        return this.timeToId(this.previousMail.sendTime)
      },
      set(id) {
        this.$set(this.previousMail, 'sendTime', this.idToTime(id))
      }
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      // 入力内容の初期化
      this.previousMail = { ...this.$store.getters['previousMail/getData'] }
      this.mixinInputData = this.previousMail
      this.mixinSetInitialData()

      // 英語表記の設定を反映
      const reservationSetting = this.$store.getters[
        'reservationSetting/getData'
      ]
      this.enAvailable = reservationSetting.englishOptionFlg === 1

      // メール固定文を設定
      this.mixinSetMailTexts()
      this.mixinMailTextAboveInput = dedent`
        ${this.mixinClinicName}からの予約確認メールです。

        ${mailTextReservationInformation({ showReservationPurpose: false })}`
      this.mixinMailTextAboveInputEn = dedent`
        Reservation confirmation email from ${this.mixinClinicNameEn}.

        ${mailTextReservationInformationEn({ showReservationPurpose: false })}`
    },
    timeToId(time) {
      const HH = time.slice(0, 2)
      return this.timeList.find(v => v.name === parseInt(HH) + ':00').id
    },
    idToTime(id) {
      return this.timeList
        .find(v => v.id === id)
        .name.split(':')
        .join('')
        .padStart(4, '0')
    },
    async updateData() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'previousMail/update',
        this.previousMail
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.setData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.previous-mail {
  @include delivery-component-style();
}
</style>
