<template>
  <div class="base-setting-tab-title" v-if="showAdmin">
    <router-link class="link" :to="link">
      <div
        class="title"
        v-bind:class="{ active: activeAddress }"
        data-e2e="base-setting-tab-title"
      >
        {{ title
        }}<span class="no-read" v-if="noreadCount !== 0" data-test="no-read">{{
          noreadCount
        }}</span
        ><span
          class="reservation-requests-count"
          v-if="reservationRequestsCount !== 0"
          data-test="reservation-requests"
          >{{ reservationRequestsCount }}</span
        >
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'BaseSettingTabTitle',
  props: {
    title: { type: String },
    link: { type: [String, Object] },
    names: { type: Array },
    noreadCount: { type: [Number, String], default: 0 },
    reservationRequestsCount: { type: [Number, String], default: 0 },
    adminOnlyFlg: { type: Boolean, default: false }
  },
  computed: {
    activeAddress() {
      return this.names.some(v => this.$route.name === v)
    },
    showAdmin() {
      if (this.adminOnlyFlg === true) {
        const adminFlg = this.$store.getters['auth/adminFlg']
        return adminFlg === 1 ? true : false
      } else {
        return true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.base-setting-tab-title {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  > a {
    display: flex;
    > .title {
      position: relative;
      width: 140px;
      font-size: 15px;
      text-align: center;
      line-height: 15px;
      cursor: pointer;
      > span {
        position: absolute;
        top: -3px;
        display: inline-block;
        margin-left: 10px;
        width: 25px;
        height: 25px;
        color: #{$white};
        font-size: 14px;
        line-height: 20px;
        background-color: #{$tomato};
        line-height: 25px;
      }
      > .no-read {
        border-radius: 50%;
      }
      > .reservation-requests-count {
        border-radius: 5px;
      }
    }
    > .active {
      border-bottom: 4px solid;
      border-color: #{$pumpkin};
      color: #{$pumpkin};
      height: 22px;
    }
    > .normal {
      height: 26px;
    }
  }
}
</style>
