<template>
  <div class="settings-left-menu" :class="{ wide }">
    <div class="menu-header" v-if="showHamburgerButton" :class="{ wide }">
      <img
        class="icon hamburger-button"
        :class="{ wide }"
        src="@/assets/images/setting_menu.png"
        width="27px"
        height="20px"
        alt="setting-menu"
        @click="toggleWide"
      />
    </div>
    <div class="menu-body">
      <div
        class="menu-item"
        v-for="menuItem in displayMenuItems"
        :key="menuItem.name"
      >
        <settings-left-menu-item :menuItem="menuItem" :wide="wide" />
      </div>
    </div>
    <div class="menu-footer">
      <router-link class="footer-link" :class="{ wide }" to="/main">
        <div class="icon allow">＜</div>
        <div class="text" v-if="wide">予約表に戻る</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import SettingsLeftMenuItem from '@/components/parts/atoms/SettingsLeftMenuItem'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'

export default {
  name: 'SettingsLeftMenu',

  components: {
    SettingsLeftMenuItem
  },

  mixins: [DetectWindowSize],

  data() {
    return {
      menuItems: [
        {
          name: '共通設定',
          shortName: '共通',
          path: '/settings/common/base',
          pathId: 'common',
          plans: ['reservation', 'karte']
        },
        {
          name: '予約設定',
          shortName: '予約',
          path: '/settings/reservation-setting/base',
          pathId: 'reservation-setting',
          plans: ['reservation', 'karte']
        },
        {
          name: '予約列管理',
          shortName: '列',
          path: '/settings/reservation-column/reservation-columns',
          pathId: 'reservation-column',
          plans: ['reservation', 'karte']
        },
        {
          name: 'スタッフ',
          shortName: '人員',
          path: '/settings/staffs',
          pathId: 'staffs',
          plans: ['reservation', 'karte']
        },
        {
          name: '勤務表・シフト',
          shortName: '勤務',
          path: '/settings/shifts/staff-working-dates',
          pathId: 'shifts',
          plans: ['reservation', 'karte']
        },
        {
          name: '待合状況',
          shortName: '待合',
          path: '/settings/waiting-statuses',
          pathId: 'waiting-statuses',
          plans: ['reservation', 'karte']
        },
        {
          name: '診察内容',
          shortName: '診察',
          path: '/settings/cares',
          pathId: 'cares',
          plans: ['reservation', 'karte']
        },
        {
          name: '配信設定',
          shortName: '配信',
          path: '/settings/delivery/previous-mail',
          pathId: 'delivery',
          plans: ['reservation', 'karte']
        },
        {
          name: '表示設定',
          shortName: '表示',
          path: '/settings/display',
          pathId: 'display',
          plans: ['reservation', 'karte']
        },
        {
          name: '傷病名',
          shortName: '傷病',
          path: '/settings/diseases',
          pathId: 'diseases',
          plans: ['karte']
        },
        {
          name: '診療項目',
          shortName: '診療',
          path: '/settings/treatments/treatments',
          pathId: 'treatments',
          plans: ['karte']
        },
        {
          name: '薬剤物品',
          shortName: '薬剤',
          path: '/settings/medicines',
          pathId: 'medicines',
          plans: ['karte']
        },
        {
          name: 'テンプレート',
          shortName: 'テン',
          path: '/settings/template/medical-content-templates',
          pathId: 'template',
          plans: ['karte']
        }
      ]
    }
  },

  computed: {
    wide() {
      return this.$store.getters['petorelu/get'].leftWideFlg
    },
    showHamburgerButton() {
      return this.$store.getters['petorelu/get'].toggleButtonFlg
    },
    displayMenuItems() {
      const karteFlg = this.$store.getters['auth/karteFlg']
      return this.menuItems.filter(menuItem =>
        karteFlg
          ? menuItem.plans.includes('karte')
          : menuItem.plans.includes('reservation')
      )
    }
  },

  methods: {
    toggleWide() {
      this.$store.dispatch('petorelu/toggleLeft')
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-left-menu {
  box-sizing: border-box;
  border-right: solid 1px #{$light-grey};
  display: flex;
  flex-direction: column;
  width: 58px;
  font-size: 15px;
  overflow-y: auto;
  &.wide {
    width: 240px;
  }
  > .menu-header {
    height: 60px;
    display: flex;
    justify-content: center;
    &.wide {
      justify-content: right;
    }
    > .hamburger-button {
      margin: 20px 0;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      &.wide {
        margin: 20px;
      }
    }
  }
  > .menu-footer {
    margin-top: auto;
    > .footer-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      &.wide {
        justify-content: left;
        padding-left: 20px;
      }
      > .allow {
        font-size: 26px;
        font-weight: bold;
        color: #{$pumpkin};
      }
      > .text {
        margin-left: 10px;
      }
    }
  }
}
</style>
