<template>
  <div class="settings-left-menu-item">
    <router-link class="link" :class="{ wide, selected }" :to="menuItem.path">
      <div class="text">{{ wide ? menuItem.name : menuItem.shortName }}</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SettingsLeftMenuItem',

  props: {
    menuItem: {
      name: { type: String, default: '' },
      shortName: { type: String, default: '' },
      path: { type: String, default: '' },
      pathId: { type: String, default: '' }
    },
    wide: { type: Boolean, default: true }
  },

  computed: {
    selected() {
      return this.$route.path.includes(`/settings/${this.menuItem.pathId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-left-menu-item {
  text-align: left;
  font-size: 15px;
  > .link {
    line-height: 60px;
    display: flex;
    justify-content: center;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    &.wide {
      justify-content: left;
      padding-left: 20px;
    }
    &.selected {
      background-color: #{$gray_ea};
    }
  }
}
</style>
