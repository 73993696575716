<template>
  <div class="waiting-statuses">
    <div class="title">待合状況</div>
    <validation-observer
      class="content"
      ref="validationObserver"
      tag="div"
      v-slot="{ invalid }"
    >
      <waiting-status-input-table
        :dragging="dragging"
        :karteFlg="karteFlg"
        :waitingStatuses="waitingStatuses"
        @input="inputValue"
        @remove="removeWaitingStatus"
        @add="addWaitingStatus"
        @ondragstart="toggleDragging(true)"
        @ondragend="toggleDragging(false)"
        @sort="setSortedWaitingStatuses"
      />
      <div class="registration-button">
        <div
          class="button"
          :style="{ '--position-left': registrationButtonPositionLeft }"
        >
          <base-button-medium-orange
            v-if="lookOnlyFlg === 0"
            class="button"
            :disabled="awaiting || dragging || invalid"
            @click="updateWaitingStatuses"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
    </validation-observer>
    <announce-popup
      v-if="popup.opened"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      @close="popup.close"
      >{{ popup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import WaitingStatusInputTable from '@/components/parts/organisms/WaitingStatusInputTable'
import { ValidationObserver } from 'vee-validate'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'WaitingStatuses',

  components: {
    AnnouncePopup,
    BaseButtonMediumOrange,
    UnsavedLeavePopup,
    ValidationObserver,
    WaitingStatusInputTable
  },

  mixins: [CheckInputDifference],

  props: {
    settingsContentsScrollLeft: { type: Number, default: 0 },
    settingsContentsWidth: { type: Number, default: 0 }
  },

  data() {
    return {
      addedCount: 0,
      awaiting: false,
      dragging: false,
      linkedContents: {
        1: '',
        2: 'reservation',
        3: 'karte',
        4: 'payment'
      },
      popup: {
        buttons: ['閉じる'],
        close: this.closePopup,
        message: '',
        opened: false,
        title: '',
        type: ''
      },
      waitingStatuses: []
    }
  },

  computed: {
    ...mapGetters({
      karteFlg: 'auth/karteFlg',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    registrationButtonPositionLeft() {
      // <div class="waiting-statuses"> の左端から登録ボタンまでの距離
      const contentsBodyPaddingLeft = 48
      const registrationButtonWidth = 200
      const positionLeft =
        this.settingsContentsWidth / 2 +
        this.settingsContentsScrollLeft -
        contentsBodyPaddingLeft -
        registrationButtonWidth / 2
      return positionLeft + 'px'
    }
  },

  created() {
    this.setWaitingStatuses()
    this.$nextTick(() => {
      this.$refs.validationObserver.validate()
    }) // 入力前から検証を行う
  },

  methods: {
    closePopup() {
      this.popup.opened = false
    },
    setWaitingStatuses() {
      this.waitingStatuses = cloneDeep(
        this.$store.getters['waitingStatuses/getData']
      )
      this.mixinInputData = this.waitingStatuses
      this.mixinSetInitialData()
    },
    inputValue({ index, key, value }) {
      if (key === 'color' && !value) {
        this.waitingStatuses[index].color = '#'
      } else if (key === 'timeSettableStatusFlg') {
        this.waitingStatuses = this.waitingStatuses.map((v, i) => {
          const newValue = index === i ? 1 : 0
          v.timeSettableStatusFlg = newValue
          return v
        })
      } else if (key === 'initialValueFlg') {
        this.waitingStatuses = this.waitingStatuses.map((v, i) => {
          const newValue = index === i ? 1 : 0
          v.initialValueFlg = newValue
          return v
        })
      } else if (key === 'linkedContent') {
        this.waitingStatuses[index].linkedContent = this.linkedContents[value]
      } else {
        this.waitingStatuses[index][key] = value
      }
    },
    removeWaitingStatus({ index }) {
      this.awaiting = true
      this.waitingStatuses.splice(index, 1)
      this.awaiting = false
    },
    addWaitingStatus() {
      this.awaiting = true
      this.addedCount++
      const waitingStatus = {
        id: -this.addedCount,
        defaultWaitingStatusId: 0,
        name: '',
        color: '#ef6c00',
        linkedContent: 'reservation',
        initialValueFlg: 0,
        timeSettableStatusFlg: 0,
        menuDisplayableStatusFlg: 1,
        reservationChangeableStatusFlg: 1,
        reservationSelectableStatusFlg: 1,
        nextWaitingStatusId: 0,
        medicalContentCreateWaitingStatusId: 0,
        medicalContentDeleteWaitingStatusId: 0,
        medicalPaymentCreateWaitingStatusId: 0,
        medicalPaymentDeleteWaitingStatusId: 0,
        inHospitalMedicalPaymentCreateWaitingStatusId: 0,
        inHospitalMedicalPaymentDeleteWaitingStatusId: 0,
        paymentCreateWaitingStatusId: 0,
        paymentDeleteWaitingStatusId: 0
      }
      this.waitingStatuses.push(waitingStatus)
      this.awaiting = false
    },
    toggleDragging(value) {
      this.dragging = value
    },
    setSortedWaitingStatuses(sortedWaitingStatuses) {
      this.waitingStatuses = sortedWaitingStatuses
      this.mixinInputData = sortedWaitingStatuses
    },
    async updateWaitingStatuses() {
      this.awaiting = true
      // 来院時間設定の確認
      const existsTimeSettableStatusFlg = this.waitingStatuses.some(
        v => v.timeSettableStatusFlg
      )
      if (!existsTimeSettableStatusFlg) {
        const message = '来院時間を設定する待合状況を選択してください。'
        this.openWarningPopup(message)
        return
      }
      // 待合状況初期選択値の確認
      const existsInitialValueFlg = this.waitingStatuses.some(
        v => v.initialValueFlg
      )
      if (!existsInitialValueFlg) {
        const message = '予約での初期値に使用する待合状況を選択してください。'
        this.openWarningPopup(message)
        return
      }
      // 現在の並び順で order を設定
      const waitingStatusesAfterInput = this.waitingStatuses.map((v, i) => {
        const order = i + 1
        return { ...v, order }
      })
      const result = await this.$store.dispatch('waitingStatuses/update', {
        waitingStatusesBeforeInput: this.mixinInitialData,
        waitingStatusesAfterInput
      })
      if (result === 'success') {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '編集しました'
        this.setWaitingStatuses()
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        if (result === 'already updated') {
          this.popup.message =
            '編集に失敗しました。\n他のユーザーにより待合状況が変更されています。\n最新の待合状況に更新します。\n編集を行う場合は再度入力をお願いします。'
          this.popup.close = () => {
            this.setWaitingStatuses()
            this.closePopup()
            this.$nextTick(() => {
              this.popup.close = this.closePopup
            })
          }
        } else {
          this.popup.message = '編集に失敗しました'
        }
      }
      this.popup.opened = true
      this.awaiting = false
    },
    openWarningPopup(message) {
      this.popup.type = 'alert'
      this.popup.title = '注意'
      this.popup.message = message
      this.popup.opened = true
      this.popup.close = () => {
        this.awaiting = false
        this.closePopup()
        this.$nextTick(() => {
          this.popup.close = this.closePopup
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.waiting-statuses {
  width: 100%;
  min-width: 680px;
  text-align: left;
  > .title {
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
  }
  > .content {
    width: 100%;
    > .registration-button {
      margin-top: 60px;
      height: 200px;
      position: relative;
      > .button {
        position: absolute;
        left: var(--position-left);
      }
    }
  }
}
</style>
