<template>
  <div class="care-input-form">
    <div class="row name">
      <text-input-form
        :requiredFlg="true"
        :placeholder="'20文字まで'"
        :maxlength="20"
        :styles="{ width: '350px' }"
        v-model="care.name"
        >診察内容名</text-input-form
      >
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'

export default {
  name: 'CareInputForm',

  components: {
    TextInputForm
  },

  props: {
    care: { type: Object }
  }
}
</script>

<style lang="scss" scoped>
.care-input-form {
  > .row ~ .row {
    margin-top: 25px;
  }
}
</style>
