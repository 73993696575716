<template>
  <div class="cares">
    <div class="title">診察内容</div>
    <div class="content">
      <div class="area buttons" v-if="!mixinSortModeFlg">
        <router-link :to="'cares/new'">
          <base-button-register
            v-if="lookOnlyFlg === 0"
            :text="'診察内容登録'"
          />
        </router-link>
        <base-sort-button
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="setSortMode"
        />
      </div>
      <div class="area buttons" v-else>
        <base-button-small-white class="button" @click="cancelSortMode"
          >キャンセル</base-button-small-white
        >
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="updateOrder"
          :disabled="waitFlg"
          >並び順登録</base-button-small-orange
        >
      </div>
      <div class="area search">
        <div class="heading">診察内容検索</div>
        <search-area
          :textBoxLabel="'検索単語'"
          :textValue="searchWord"
          :placeholder="'診察内容名'"
          :disabled="mixinSortModeFlg"
          v-model="searchWord"
        />
      </div>
      <div class="area list">
        <div class="heading">
          診察内容一覧<span v-if="mixinSortModeFlg">{{
            mixinSortModeMessage
          }}</span>
        </div>
        <list-table
          class="cares-list"
          :headerData="headers"
          :propertyData="properties"
          :bodyData="displayCares"
          :sortModeFlg="mixinSortModeFlg"
          @click="openShowPage"
          @order="changeOrder"
        />
      </div>
    </div>
    <announce-popup
      v-if="mixinSortPopup.showFlg"
      :type="mixinSortPopup.type"
      :title="mixinSortPopup.title"
      :buttons="mixinSortPopup.buttons"
      @close="mixinCloseSortPopup"
      >{{ mixinSortPopup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import SearchArea from '@/components/parts/molecules/SearchArea'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import ApiOrder from '@/components/mixins/ApiOrder'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'

export default {
  name: 'Cares',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    SearchArea,
    ListTable,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [ApiOrder, CheckInputDifference],

  data() {
    return {
      headers: ['標準', '診察内容名'],
      properties: ['order', 'name'],
      searchWord: '',
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      cares: 'cares/getData'
    }),
    displayCares() {
      const data = this.mixinSortModeFlg ? this.mixinInputData : this.cares
      return data.filter(
        v =>
          v.name
            .toLowerCase()
            .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !== -1
      )
    }
  },

  watch: {
    mixinSortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.mixinInputData = this.cares
      this.mixinSetInitialData()
    },
    openShowPage(id) {
      this.$router.push({ path: `cares/${id}` })
    },
    setSortMode() {
      this.mixinSortModeFlg = true
      this.searchWord = ''
    },
    cancelSortMode() {
      this.mixinSortModeFlg = false
    },
    changeOrder(data) {
      this.mixinInputData = data.map(v1 =>
        this.mixinInputData.find(v2 => v2.id === v1.id)
      )
    },
    async updateOrder() {
      this.waitFlg = true
      await this.mixinUpdateOrder('cares', this.mixinInputData)
      if (this.mixinSortSucceeded) this.mixinSetInitialData()
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cares {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .title {
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
  }
  > .content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .area {
      > .heading {
        margin-bottom: 16px;
        font-size: 15px;
        font-weight: bold;
      }
      &.buttons {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin-left: 20px;
        }
      }
      &.search {
        margin-top: 16px;
      }
      &.list {
        margin-top: 26px;
        ::v-deep .list-table .table-body {
          max-height: 560px;
        }
      }
    }
  }
}
</style>
