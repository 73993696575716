<template>
  <div class="care-show">
    <div class="link">
      <router-link :to="{ name: 'cares' }"><base-back-link /></router-link>
    </div>
    <div class="setting-title">診察内容詳細</div>
    <div class="wrap-content">
      <div class="button-area">
        <router-link
          :to="{ name: 'care-edit', params: this.id }"
          class="button"
        >
          <base-button-small-white v-if="lookOnlyFlg === 0"
            >編集</base-button-small-white
          >
        </router-link>
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="alertFlg = true"
          >削除</base-button-small-red
        >
      </div>
      <div class="content-title">基本項目</div>
      <div class="content-area">
        <show-data-list :displayKeys="displayKeys" :keys="keys" :data="data" />
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="alertFlg = false"
      @decision="deleteCare"
      @close="closeAlert"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'CareShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayKeys: ['診察内容名'],
      keys: ['name'],
      data: {},
      alertFlg: false,
      waitFlg: false,
      type: '',
      title: '',
      buttons: ['閉じる'],
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getCare: 'cares/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.initializeAlert()
    this.data = this.getCare(this.id)
  },

  methods: {
    initializeAlert() {
      this.alertFlg = false
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
    },
    async deleteCare() {
      this.waitFlg = true
      const result = await this.$store.dispatch('cares/delete', this.id)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
      this.$router.push({ name: 'cares' })
    }
  }
}
</script>

<style lang="scss" scoped>
.care-show {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .link {
    margin-left: -26px;
  }
  > .setting-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .wrap-content {
    margin-top: 20px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .content-title {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      margin-top: 20px;
    }
    > .content-area {
      margin-top: 25px;
    }
  }
}
</style>
