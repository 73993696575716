<template>
  <div class="send-mail-template-show">
    <base-back-link class="base-back-link" @click="backSendMailTemplates" />
    <div class="title">メール定型文詳細</div>
    <div class="button-wrapper">
      <base-button-small-white
        class="button"
        v-if="lookOnlyFlg === 0"
        @click="pushToSendMailTemplateEdit"
        >編集</base-button-small-white
      >
      <base-button-small-red
        class="button"
        v-if="lookOnlyFlg === 0"
        @click="openAlert"
        >削除</base-button-small-red
      >
    </div>
    <div class="content">
      <div class="heading">基本項目</div>
      <div class="list">
        <show-data-list
          :displayKeys="displayKeys"
          :keys="keys"
          :data="displayData"
        />
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="closeAlert"
      @decision="deleteApi"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :type="type"
      :disabled="waitFlg"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import dedent from 'dedent'
import { mailTextNotReply, mailTextNotReplyEn } from '@/utils/mail_text'

export default {
  name: 'SendMailTemplateShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      reservationSetting: 'reservationSetting/getData'
    }),
    clinicName() {
      return this.$store.getters['clinic/getData'].name
    },
    clinicNameEn() {
      const clinic = this.$store.getters['clinic/getData']
      return clinic.nameEnglish || clinic.name
    },
    sendMailTemplate() {
      return this.$store.getters['sendMailTemplates/getDataById'](this.id)
    },
    enAvailable() {
      return this.reservationSetting.englishOptionFlg === 1
    },
    displayKeys() {
      return [
        '定型文名',
        '件名',
        '本文',
        ...(this.enAvailable ? ['件名（英語）', '本件（英語）'] : [])
      ]
    },
    keys() {
      return [
        'name',
        'title',
        'message',
        ...(this.enAvailable ? ['titleEnglish', 'messageEnglish'] : [])
      ]
    },
    displayData() {
      const {
        name,
        title,
        titleEnglish,
        message,
        messageEnglish
      } = this.sendMailTemplate
      return {
        name,
        title,
        titleEnglish,
        message: this.toMailText(message),
        messageEnglish: this.toMailTextEn(messageEnglish)
      }
    }
  },

  methods: {
    toMailText(message) {
      return dedent`
        ${this.clinicName}より〇〇様へ

        ${message}

        ${mailTextNotReply}`
    },
    toMailTextEn(message) {
      return dedent`
        From ${this.clinicNameEn} to 〇〇.

        ${message}

        ${mailTextNotReplyEn}`
    },
    backSendMailTemplates() {
      this.$router.push({ name: 'send-mail-templates' })
    },
    pushToSendMailTemplateEdit() {
      this.$router.push({ name: 'send-mail-template-edit', params: this.id })
    },
    openAlert() {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
    },
    async deleteApi() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'sendMailTemplates/delete',
        this.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.alertFlg = false
      this.backSendMailTemplates()
    }
  }
}
</script>

<style lang="scss" scoped>
.send-mail-template-show {
  margin-bottom: 24px;
  > .base-back-link {
    margin: -40px 0 0 -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    margin-top: 20px;
    padding-bottom: 20px;
  }
  > .button-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    > .button {
      margin-left: 20px;
    }
  }
  > .content {
    margin-top: 16px;
    > .heading {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .list {
      margin-top: 25px;
    }
  }
}
</style>
