<!-- handle クラスは WaitingStatusInputTable.vue で定義-->
<template>
  <div class="waiting-status-input-table-row">
    <div class="table-body-row-cell center reorder handle">
      <div class="drag-reorder-icon">
        <img
          src="@/assets/images/menu.png"
          width="28px"
          height="16px"
          alt="menu"
        />
      </div>
    </div>
    <div class="table-body-row-cell name">
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, notSurrogatePair: true }"
        v-slot="{ errors }"
      >
        <base-text-box
          data-test="base-text-box name"
          :value="waitingStatus.name"
          :maxlength="10"
          :styles="{ width: '190px' }"
          @input="inputValue(index, 'name', $event)"
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div class="table-body-row-cell color">
      <validation-provider
        class="validation-provider"
        :rules="{ colorCode: true }"
        v-slot="{ errors }"
      >
        <div class="color-input-field">
          <div
            v-if="hasColorCode"
            class="color-display"
            data-test="color-display"
            :style="{ backgroundColor: waitingStatus.color }"
          >
            <span class="triangle left" :style="{ '--bg-color': textColor }" />
            <div class="waiting-status-name" :style="{ color: textColor }">
              <span>{{ waitingStatus.name }}</span>
            </div>
            <span class="triangle right" :style="{ '--bg-color': textColor }" />
          </div>
          <div
            v-else
            class="color-display ng-color-code"
            data-test="color-display-error"
          >
            <span>error</span>
          </div>
          <base-text-box
            data-test="base-text-box color"
            :value="waitingStatus.color"
            :maxlength="7"
            :styles="{ width: '90px', height: '33px' }"
            @input="inputValue(index, 'color', $event)"
          />
          <div class="color-chart-button" @click="showColorChart = true">
            <img
              src="@/assets/images/box_menu_pumpkin_32px.png"
              width="20px"
              height="20px"
              alt="box-menu-pumpkin"
            />
          </div>
          <div
            v-if="showColorChart"
            class="color-chart-wrapper"
            data-test="color-chart-wrapper"
          >
            <base-color-chart
              class="color-chart"
              :value="waitingStatus.color"
              @click="setColor(index, $event)"
            />
            <div
              class="overlay"
              data-test="overlay"
              @click.self="showColorChart = false"
            />
          </div>
        </div>
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div class="table-body-row-cell center menu-displayable-status-flg">
      <div class="checkbox">
        <base-check-box
          :numFlg="true"
          :isChecked="waitingStatus.menuDisplayableStatusFlg"
          :labelText="''"
          @input="inputValue(index, 'menuDisplayableStatusFlg', $event)"
        />
      </div>
    </div>
    <div class="table-body-row-cell center time-settable-status-flg">
      <div class="radiobutton">
        <base-radio-button
          data-test="base-radio-button time-settable-status-flg"
          :option="{
            id: radiobuttonIds.timeSettableStatusFlg + index,
            eachValue: index,
            labelName: ''
          }"
          :value="timeSettableWaitingStatusIndex"
          @input="inputValue(index, 'timeSettableStatusFlg', $event)"
        />
      </div>
    </div>
    <div class="table-body-row-cell center initial-value-flg">
      <div class="radiobutton">
        <base-radio-button
          data-test="base-radio-button initial-value-flg"
          :option="{
            id: radiobuttonIds.initialValueFlg + index,
            eachValue: index,
            labelName: ''
          }"
          :value="initialValueWaitingStatusIndex"
          @input="inputValue(index, 'initialValueFlg', $event)"
        />
      </div>
    </div>
    <div class="table-body-row-cell next-waiting-status-id">
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box next-waiting-status-id"
          :value="waitingStatus.nextWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '180px' }"
          @input="inputValue(index, 'nextWaitingStatusId', $event)"
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div v-if="karteFlg" class="table-body-row-cell linked-content">
      <base-select-box
        data-test="base-select-box linked-content"
        :value="toLinkedContentId(waitingStatus.linkedContent)"
        :selectData="selectListLinkedContents"
        :styles="{ width: '120px' }"
        @input="inputValue(index, 'linkedContent', $event)"
      />
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell reservation-selectable-status-flg"
    >
      <base-select-box
        data-test="base-select-box reservation-selectable-status-flg"
        :value="waitingStatus.reservationSelectableStatusFlg"
        :selectData="selectListPossibilities"
        :styles="{ width: '120px' }"
        @input="inputValue(index, 'reservationSelectableStatusFlg', $event)"
      />
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell reservation-changeable-status-flg"
    >
      <base-select-box
        data-test="base-select-box reservation-changeable-status-flg"
        :value="waitingStatus.reservationChangeableStatusFlg"
        :selectData="selectListPossibilities"
        :styles="{ width: '120px' }"
        @input="inputValue(index, 'reservationChangeableStatusFlg', $event)"
      />
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell medical-content-create-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box medical-content-create-waiting-status-id"
          :value="waitingStatus.medicalContentCreateWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '180px' }"
          @input="
            inputValue(index, 'medicalContentCreateWaitingStatusId', $event)
          "
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell medical-content-delete-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box medical-content-delete-waiting-status-id"
          :value="waitingStatus.medicalContentDeleteWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '180px' }"
          @input="
            inputValue(index, 'medicalContentDeleteWaitingStatusId', $event)
          "
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell medical-payment-create-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box medical-payment-create-waiting-status-id"
          :value="waitingStatus.medicalPaymentCreateWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '180px' }"
          @input="
            inputValue(index, 'medicalPaymentCreateWaitingStatusId', $event)
          "
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell medical-payment-delete-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box medical-payment-delete-waiting-status-id"
          :value="waitingStatus.medicalPaymentDeleteWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '180px' }"
          @input="
            inputValue(index, 'medicalPaymentDeleteWaitingStatusId', $event)
          "
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell in-hospital-medical-payment-create-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box in-hospital-medical-payment-create-waiting-status-id"
          :value="waitingStatus.inHospitalMedicalPaymentCreateWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '220px' }"
          @input="
            inputValue(
              index,
              'inHospitalMedicalPaymentCreateWaitingStatusId',
              $event
            )
          "
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell in-hospital-medical-payment-delete-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box in-hospital-medical-payment-delete-waiting-status-id"
          :value="waitingStatus.inHospitalMedicalPaymentDeleteWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '220px' }"
          @input="
            inputValue(
              index,
              'inHospitalMedicalPaymentDeleteWaitingStatusId',
              $event
            )
          "
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell payment-create-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box payment-create-waiting-status-id"
          :value="waitingStatus.paymentCreateWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '180px' }"
          @input="inputValue(index, 'paymentCreateWaitingStatusId', $event)"
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="karteFlg"
      class="table-body-row-cell payment-delete-waiting-status-id"
    >
      <validation-provider
        class="validation-provider"
        :rules="{ requiredRule: true, oneOfRule: selectListWaitingStatuses }"
        v-slot="{ errors }"
      >
        <base-select-box
          data-test="base-select-box payment-delete-waiting-status-id"
          :value="waitingStatus.paymentDeleteWaitingStatusId"
          :selectData="selectListWaitingStatuses"
          :styles="{ width: '180px' }"
          @input="inputValue(index, 'paymentDeleteWaitingStatusId', $event)"
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div
      v-if="!isDefaultStatus"
      class="table-body-row-cell center delete"
      data-test="cell-delete"
    >
      <base-button-trash @click="removeWaitingStatus(index)" />
    </div>
  </div>
</template>

<script>
import '@/utils/validation_rules'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseColorChart from '@/components/parts/atoms/BaseColorChart'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import ColorMethods from '@/components/mixins/ColorMethods'
import { VALID_COLOR_CODE_REGEX } from '@/utils/define'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'WaitingStatusInputTableRow',

  components: {
    BaseButtonTrash,
    BaseCheckBox,
    BaseColorChart,
    BaseRadioButton,
    BaseSelectBox,
    BaseTextBox,
    ValidationProvider
  },

  mixins: [ColorMethods],

  props: {
    index: { type: Number },
    initialValueWaitingStatusIndex: { type: Number },
    karteFlg: { type: Number },
    timeSettableWaitingStatusIndex: { type: Number },
    waitingStatus: { type: Object },
    waitingStatuses: { type: Array }
  },

  data() {
    return {
      linkedContentIds: {
        '': 1,
        reservation: 2,
        karte: 3,
        payment: 4
      },
      radiobuttonIds: {
        timeSettableStatusFlg: 101,
        initialValueFlg: 201
      },
      selectListLinkedContents: [
        { id: 1, name: 'なし' },
        { id: 2, name: '予約' },
        { id: 3, name: 'カルテ' },
        { id: 4, name: '会計' }
      ],
      selectListPossibilities: [
        { id: 1, name: '可能' },
        { id: 0, name: '不可能' }
      ],
      showColorChart: false
    }
  },

  computed: {
    isDefaultStatus() {
      return this.waitingStatus.defaultWaitingStatusId !== 0
    },
    hasColorCode() {
      return VALID_COLOR_CODE_REGEX.test(this.waitingStatus.color)
    },
    selectListWaitingStatuses() {
      return [{ id: 0, name: 'なし' }, ...this.waitingStatuses]
        .filter(v => v.id !== this.waitingStatus.id)
        .map(v => ({ id: v.id, name: v.name }))
    },
    textColor() {
      return this.mixinAdaptTextColor(this.waitingStatus.color)
    }
  },

  methods: {
    inputValue(index, key, value) {
      this.$emit('input', { index, key, value })
    },
    setColor(index, color) {
      const isSameColor = this.waitingStatus.color === color
      if (isSameColor) {
        this.showColorChart = false
      } else {
        this.$emit('input', { index, key: 'color', value: color })
      }
    },
    removeWaitingStatus(index) {
      this.$emit('remove', { index })
    },
    toLinkedContentId(linkedContent) {
      return this.linkedContentIds[linkedContent]
    }
  }
}
</script>

<style lang="scss" scoped>
$question-mark-width: 25px;
$cell-widths: (
  reorder: 40px,
  name: 195px,
  color: 220px,
  menu-displayable-status-flg: 80px,
  time-settable-status-flg: 100px + $question-mark-width,
  initial-value-flg: 145px,
  next-waiting-status-id: 185px,
  linked-content: 125px,
  reservation-selectable-status-flg: 125px,
  reservation-changeable-status-flg: 125px,
  medical-content-create-waiting-status-id: 185px,
  medical-content-delete-waiting-status-id: 185px,
  medical-payment-create-waiting-status-id: 185px,
  medical-payment-delete-waiting-status-id: 185px,
  in-hospital-medical-payment-create-waiting-status-id: 225px,
  in-hospital-medical-payment-delete-waiting-status-id: 225px,
  payment-create-waiting-status-id: 185px,
  payment-delete-waiting-status-id: 185px,
  delete: 40px
);

@mixin color-input-field {
  > .color-input-field {
    display: flex;
    align-items: center;
    gap: 0 6px;
    > .color-display {
      padding: 0;
      width: 80px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: none;
      transition: opacity 0.2s, background-color 0.2s;
      background-color: var(--bg-color);
      box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
      position: relative;
      &.ng-color-code {
        color: red;
        background-color: rgb(230, 230, 230);
        font-size: 16px;
      }
      > .triangle {
        position: absolute;
        top: 9.5px;
        width: 8px;
        height: 10px;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        background-color: var(--bg-color);
        &.left {
          left: 4px;
        }
        &.right {
          right: 3px;
        }
      }
      > .waiting-status-name {
        max-width: 50px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
      }
    }
    > .color-chart-button {
      cursor: pointer;
      box-sizing: border-box;
      width: 33px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: solid 2px #{$light-grey};
      background-color: #{$white};
      &:hover {
        opacity: 0.8;
        background-color: #{$plus_ee_gray};
      }
      &:active {
        position: relative;
        top: 1px;
        height: 32px;
      }
    }
    > .color-chart-wrapper {
      position: relative;
      height: 33px;
      > .color-chart {
        position: absolute;
        z-index: 1100;
        top: 0;
        left: 0;
      }
    }
  }
}

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// コンポーネントのスタイル
.waiting-status-input-table-row {
  box-sizing: border-box;
  width: fit-content;
  height: 70px;
  display: flex;
  align-items: center;
  gap: 0 40px;
  border-radius: 6px;
  padding: 0 20px;
  > .table-body-row-cell {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    &.center {
      justify-content: center;
    }
    @each $key, $width in $cell-widths {
      &.#{$key} {
        width: $width;
      }
    }
    > .drag-reorder-icon {
      cursor: move;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > .validation-provider {
      position: relative;
      @include color-input-field;
      > .error {
        position: absolute;
        z-index: 1;
        color: #{$tomato};
        font-size: 12px;
        text-align: left;
        white-space: nowrap;
      }
    }
    > .checkbox {
      display: inline-block;
    }
    > .radiobutton {
      width: 20px;
    }
  }
}
</style>
