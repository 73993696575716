<template>
  <div class="settings">
    <the-settings-header />
    <div class="settings-body">
      <settings-left-menu />
      <div class="settings-contents" ref="settingsContents">
        <div
          class="contents-body"
          @touchstart="startTimer"
          @touchmove="onMove"
          @touchend="stopTimer"
        >
          <router-view
            :settingsContentsWidth="settingsContentsWidth"
            :settingsContentsScrollLeft="settingsContentsScrollLeft"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheSettingsHeader from '@/components/globals/TheSettingsHeader'
import SettingsLeftMenu from '@/components/parts/organisms/SettingsLeftMenu'

export default {
  name: 'Settings',

  components: {
    SettingsLeftMenu,
    TheSettingsHeader
  },

  data() {
    return {
      touchTime: 0,
      intervalId: null,
      settingsContentsPosition: { top: 0, bottom: 0 },
      touchPoint: { y: 0 },
      settingsContentsObserver: null,
      settingsContentsWidth: 0,
      settingsContentsScrollLeft: 0
    }
  },

  computed: {
    diffTouchTop() {
      return this.touchPoint.y - this.settingsContentsPosition.top
    },
    diffTouchBottom() {
      return this.settingsContentsPosition.bottom - this.touchPoint.y
    },
    scrollUpFlg() {
      return this.diffTouchTop <= 30 ? true : false
    },
    scrollDownFlg() {
      return this.diffTouchBottom <= 30 ? true : false
    }
  },

  watch: {
    $route: function(to, from) {
      if (this.$refs.settingsContents.scrollTop !== 0) {
        this.$refs.settingsContents.scrollTo(0, 0)
      }
    },
    touchTime: function() {
      if (this.scrollUpFlg) {
        this.scrollUp()
      } else if (this.scrollDownFlg) {
        this.scrollDown()
      } else {
        return
      }
    }
  },

  mounted() {
    // 設定項目画面の表示域の横幅を取得
    this.settingsContentsObserver = new ResizeObserver(() => {
      this.settingsContentsWidth = this.$refs.settingsContents.clientWidth
    })
    this.settingsContentsObserver.observe(this.$refs.settingsContents)

    // 設定項目画面の横スクロールの距離を取得
    this.$nextTick(() => {
      this.$refs.settingsContents.addEventListener('scroll', this.setScrollLeft)
    })
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
    this.settingsContentsObserver.disconnect()
    this.$refs.settingsContents.removeEventListener(
      'scroll',
      this.setScrollLeft
    )
  },

  methods: {
    setScrollLeft() {
      this.settingsContentsScrollLeft = this.$refs.settingsContents.scrollLeft
    },
    onMove(e) {
      this.setElementDimensions()
      this.touchPoint = {
        y: e.touches[0].clientY
      }
    },
    setElementDimensions() {
      this.settingsContentsPosition = {
        top: this.$refs.settingsContents.getBoundingClientRect().top,
        bottom: this.$refs.settingsContents.getBoundingClientRect().bottom
      }
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        this.touchTime++
      }, 10)
    },
    stopTimer() {
      clearInterval(this.intervalId)
      this.touchTime = 0
    },
    scrollDown() {
      this.$refs.settingsContents.scrollBy(0, 5)
    },
    scrollUp() {
      this.$refs.settingsContents.scrollBy(0, -5)
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; // 勤務表・シフトなどはみ出している要素が表示されないのはこいつのせい。
  > .settings-body {
    flex-grow: 1;
    display: flex;
    width: 100%;
    height: calc(100% - 64px); // 64px settings-headerのheight
    > .settings-contents {
      width: calc(100% - 240px); // 240px settings-left-menuのwidth
      display: flex;
      flex-grow: 1;
      overflow: auto;
      > .contents-body {
        flex-grow: 1;
        display: flex;
        padding: 24px 48px;
      }
    }
  }
}
</style>
