<template>
  <div class="send-mail-template-edit">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="title">メール定型文編集</div>
      <div class="heading">基本項目</div>
      <send-mail-template-input-form :sendMailTemplate="sendMailTemplate" />
      <div class="button-wrap">
        <base-button-medium-white
          class="button"
          @click="backSendMailTemplateShow"
          >戻る</base-button-medium-white
        >
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="invalid || waitFlg"
          @click="put"
          >登録</base-button-medium-orange
        >
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="closePopup"
        :title="title"
        :buttons="['閉じる']"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import SendMailTemplateInputForm from '@/components/parts/organisms/SendMailTemplateInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'

export default {
  name: 'SendMailTemplateEdit',

  components: {
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    ValidationObserver,
    SendMailTemplateInputForm,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      sendMailTemplate: {
        ...this.$store.getters['sendMailTemplates/getDataById'](this.id)
      },
      waitFlg: false,
      alertFlg: false,
      title: '',
      type: '',
      popupMessage: '',
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.mixinInputData = this.sendMailTemplate
    this.mixinSetInitialData()
  },

  methods: {
    backSendMailTemplateShow() {
      this.$router.push({ name: 'send-mail-template-show', params: this.id })
    },
    async put() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'sendMailTemplates/put',
        this.sendMailTemplate
      )
      if (result === true) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.title = '失敗'
        this.type = 'failure'
        if (result === 'no data' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中のメール定型文は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closePopup() {
      this.alertFlg = false
      if (this.title === '完了') {
        this.$store.dispatch('petorelu/okLeave')
        this.backSendMailTemplateShow()
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'send-mail-templates' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.send-mail-template-edit {
  margin-top: -40px;
  > .observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .heading {
      margin: 20px 0;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .button-wrap {
      display: flex;
      justify-content: center;
      margin: 60px 0;
      > .button {
        margin: 0 16px;
      }
    }
  }
}
</style>
